import React, { useState } from 'react'
import classes from './ContactUs.module.css'
import PrimaryButton from '../../components/Buttons/PrimryButton/PrimaryButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import whatsapp from '../../assets/social/whatsapp.svg'
import axios from 'axios';
import { countryCode, contact } from '../../utils/utils';

function ContactUs() {
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [location, setLocation] = useState()
    const [number, setNumber] = useState();
    const [enquiry, setEnquiry] = useState();
    const [formMessage, setFormMessage] = useState('');
    const [isError, setIsError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [country, setCountry] = useState("");

    async function enquirySubmit() {
        let emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        setFormMessage('');
        if (!name) {
            setIsError(true);
            setFormMessage("Please enter your name.");
            return false;
        }
        if (!email) {
            setIsError(true);
            setFormMessage("Please enter your email.");
            return false;
        } else if (!emailRegex.test(email)) {
            setIsError(true);
            setFormMessage("Please enter valid email.");
            return false;
        }
        if(!country){
            setIsError(true);
            setFormMessage('Please select your country')
            return false;
        }
        if (!number) {
            setIsError(true);
            setFormMessage("Please enter your phone.");
            return false;
        }else if (number.length < 10) {
            setIsError(true);
            setFormMessage("Please enter valid phone.");
            return false;
        }
        if (!location) {
            setIsError(true);
            setFormMessage("Please enter your location.");
            return false;
        }

        // Before sending the request, add the class
        setSubmitting(true);      
        await axios({
            url: 'https://evalexam.com:8443/requestDemo',
            method: "POST",
            data: {
                "name": name,
                "contact": number,
                "emailId": email,
                "message": enquiry,
			 "country": country,
                    "location": location,
                }
        }).then(function (response) {
            if(response.data.status){
                setIsError(false);
            }
            setFormMessage(response.data.message);

        }).catch(function (error){
            console.log(error);
        }).finally(function () {
            // Remove class after request is complete
            setSubmitting(false);
        })
}
    return (
        <div className={classes.main}>
            <div className={classes.contactUsContainer}>
                <div className={classes.containerLeft}>
                    <h1 className={classes.heading}>Contact Us</h1>
                    <div className={classes.locationContainer}>
                        <div className={classes.logo}>
                            <FontAwesomeIcon icon={faLocationDot} />
                        </div>
                        <div className={classes.locationContent}>
                            <h6 className={classes.locationHeading}>Vir Softect Private Limited.</h6>
                            <p > A 306, The i-Thum Plot No A 40, Block A, Industrial Area,, Sector 62, Noida, Uttar Pradesh 201309</p>
                        </div>
                    </div>
                    <div className={classes.phoneContainer}>
                        <div className={classes.logo}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                        <div className={classes.phoneContent}>
                            <p>{contact.mail}</p>
                        </div>
                    </div>
                    <div className={classes.phoneConainer} style={{ display: "flex", marginTop: "10px" }}>
                        <div className={classes.logo}>
                            <FontAwesomeIcon icon={faPhone} />
                        </div>
                        <div className={classes.phoneContent}>
                            <p>18005717711, 0120 - 4325 497, <br></br>{contact.phone}</p>
                        </div>

                    </div>
                </div>
                <div className={classes.containerRight}>
                    <form className={classes.contactForm}>
                        <input type="text" className={classes.nameInput} onChange={(e) => setName(e.target.value)} placeholder='Name' />
                        <input type="email" className={classes.emailInput} onChange={(e) => setEmail(e.target.value)} placeholder='Email Address' />
                        <div className={classes.numberInput}>
                            <select name="countryCode" id="countryCode" height="100" onChange={e => setCountry(e.target.value)}>
                            <option value=''>Country</option>
                                {
                                    countryCode?.map(country => {
                                        return <option value={`${country.name}`}>{country.name}</option>
                                    })
                                }
                            </select>
                            <input type="number" className={classes.numberInput} onChange={(e) => setNumber(e.target.value)} placeholder='Phone Number' />
                        </div>
                        <input type="text" className={classes.nameInput} onChange={(e) => setLocation(e.target.value)} placeholder='Location' />
                        <textarea type="text" className={classes.enquiryInput} onChange={(e) => setEnquiry(e.target.value)} placeholder='Enquiry For?' />
                        <span className={`${classes.formMessage} ${isError ? classes.error : classes.success}`}>{formMessage}</span>
                        <PrimaryButton
                            type={'button'}
                            style={{ width: '75%', margin: '1rem', fontSize: '0.8rem' }}
                            className={`${submitting ? classes.disableButton : ''}`}
                            onClick={enquirySubmit}
                        >Submit</PrimaryButton>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ContactUs;