import React from 'react'
import classes from './DescriptiveTest.module.css'
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import mobileAccess from '../../assets/features/mobile application- mobile access.png';
import userInterface from '../../assets/features/mobile application - user interface.png';
import testTakingFuncn from '../../assets/features/mobile application- test taking functionality.jpg'
import performanceAnalytics from '../../assets/features/mobile application- progress tracking.jpg'

function MoblieApplication() {
    return <div className={classes.main}>
        <QuestionBank
            title={'Mobile Appliction'}
            content={"Integrating a mobile application into an online examination portal can provide a convenient and accessible platform for candidates to access and participate in assessments."} />
        <OurVisionAndMission
            title={'Mobile Access'}
            image={mobileAccess}
            content={"A mobile application allows candidates to access the online examination portal conveniently from their smartphones or tablets. This ensures flexibility and accessibility, enabling candidates to take tests anytime and anywhere, as long as they have an internet connection."}
        />
        <OurVisionAndMission
            title={'User Interface'}
            image={userInterface}
            content={"The mobile application provides a user-friendly interface optimized for mobile devices. It offers intuitive navigation, responsive design, and touch-friendly controls, enhancing the overall user experience for candidates."}
            right
        />
        <OurVisionAndMission
            title={'Test-Taking Functionality'}
            image={testTakingFuncn}
            content={"The mobile application enables candidates to take tests directly from their mobile devices. They can view and answer test questions, navigate through the test sections, and submit their responses seamlessly."}
        />
        <OurVisionAndMission
            title={'Performance Analytics'}
            image={performanceAnalytics}
            content={"The mobile application can provide candidates with instant result on their performance, displaying scores and performance metrics immediately after completing a test. "}
            right
        />
    </div>

}

export default MoblieApplication