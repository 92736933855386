import React, { useState } from 'react';
import classes from './HomeScreen.module.css';
import Section1A from '../../components/Section1A/Section1A';
import Section1B from '../../components/Section1B/Section1B';
import Card from '../../components/Cards/Card';
import PrimaryButton from '../../components/Buttons/PrimryButton/PrimaryButton';
import WeCertifiedUnder from '../../components/WeCertifiedUnder/WeCertifiedUnder';
import ReactangleCard from '../../components/Cards/ReactangleCard';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import { countryCode } from '../../utils/utils';
import CircleCard from '../../components/Cards/CircleCard';
//imported images
import homescreenTopBackgroundImage from '../../assets/homescreen/homeScreenBackGroundImage.svg'
import handShakeImage1 from '../../assets/homescreen/handShake1.png';
import bannerImage from '../../assets/homescreen/bannerImage.jpg';
import freeTrial from '../../assets/homescreen/freeTrial.svg'
import AkashLogo from '../../assets/clients/AakashInstitute.png';
import CareerPointLogo from '../../assets/clients/Careerpoint.png';
import FiitJeeLogo from '../../assets/clients/FIITJEE.jpg';
import ResonanceLogo from '../../assets/clients/Resonance.png'
import axios from 'axios';
import whiteLabel from '../../assets/homescreen/white-label-solutions.jpg';
import concurrency from '../../assets/homescreen/concurrency.jpg';
import backgroundImage from '../../assets/homescreen/bannerImage.jpg';
import physicsWallah from '../../assets/clients/pw.jpg';
import LandingPopUp from '../../components/LandingPopUp/LandingPopUp';


const recruitmentAndAssessment = [
  {
    title: 'Assessment for educational institutes',
    content: 'Conduct mock and practice tests, evaluate the performance of students, identify areas of improvement, and provide feedback for further learning.'
  },
  {
    title: 'Entrance exam management',
    content: 'Our AI proctoring tools will enhance the efficiency and integrity of entrance exam management.'
  },
  {
    title: 'Remote Proctoring',
    content: 'Our AI proctoring tools allow for remote monitoring of candidates to detect and flag suspicious activities. Our robust anti-cheating mechanisms enhance exam integrity.'
  },
  {
    title: 'Olympiad',
    content: 'Our reliable online examination platform meets your requirements and supports features such as question bank, randomization of questions, time limits and secures exam delivery.'
  },
  {
    title: 'Descriptive tests',
    content: 'Our platform supports descriptive tests and allows the students to type in their answers on screen and upload documents as and when required.'
  },
]

const revolutionizedConductingExams = [
  {
    title: 'Assessment for Corporate',
    content: 'On our platform your organization can design and implement effective assessments that provide valuable insights for decision-making, talent development, and organizational growth.'
  },
  {
    title: 'Skill Assessment',
    content: 'Our platform helps you in making hiring decisions, identifying skill gaps, analytics-based training and career development'
  },
  {
    title: 'Recruitment Assessment',
    content: 'Our robust security features like browser lockdown, disabling copy-paste, preventing screenshots, monitoring through webcams and anti-cheating algorithms ensure that your exams are conducted fairly.'
  },
  {
    title: 'Remote Proctoring',
    content: 'With the use of technology such as webcams, microphones, and screen-sharing software, monitor and record the test-takers activities during the exam.'
  },
  {
    title: 'Campus recruitment',
    content: 'Make recruitment a hassle-free process by hiring the best candidates directly from the campus'
  },
]


function Homescreen() {
  const [country, setCountry] = useState('')
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [enquiry, setEnquiry] = useState();
  const [location, setLocation] = useState();
  const [formMessage, setFormMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // const [landingPopUP, setLandingPopUp] = useState(true);

  async function enquirySubmit() {
    let emailRegex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    setFormMessage('');
    if (!name) {
      setIsError(true);
      setFormMessage("Please enter your name.");
      return false;
    }
    if (!email) {
      setIsError(true);
      setFormMessage("Please enter your email.");
      return false;
    } else if (!emailRegex.test(email)) {
      setIsError(true);
      setFormMessage("Please enter valid email.");
      return false;
    }
    if (!country) {
      setIsError(true);
      setFormMessage('Please select your country')
      return false;
    }
    if (!number) {
      setIsError(true);
      setFormMessage("Please enter your phone.");
      return false;
    }else if (number.length < 10) {
      setIsError(true);
      setFormMessage("Please enter valid phone.");
      return false;
    }
    if (!location) {
      setIsError(true);
      setFormMessage("Please enter your location.");
      return false;
    }
    setSubmitting(true);
    await axios({
      url: 'https://evalexam.com:8443/requestDemo',
      method: "POST",
      data: {
        "name": name,
        "contact": number,
        "emailId": email,
        "message": enquiry,
        "country": country,
        "location": location,
      }
    }).then(function (response) {
      if (response.data.status) {
        setIsError(false);
      }
      setFormMessage(response.data.message);

    }).catch(function (error) {
      console.log(error);
    }).finally(function () {
      // Remove class after request is complete
      setSubmitting(false);
    })
  }

  return <div className={classes.main}>
    <div className={classes.section1}>
      <div className={classes.container}>
        <Section1A />
        <Section1B />
      </div>
      <OurVisionAndMission title={'Concurrency'} image={concurrency} content={'Our Platform can handle high traffic and user load of over 100K concurrent exams without compromising the performance.'} />
      <OurVisionAndMission
        title={'White-Label Solutions'}
        right
        image={whiteLabel}
        content={"Turn your platform into a brand with the white-label version of your online examination system."}
      />
      <img src={homescreenTopBackgroundImage} className={classes.backgroundImage} alt="Home Screen BackGround" />
    </div>
    <div className={classes.section4}>
      <div className={classes.section4Heading}>
        <h1 className={classes.headingText}>Recruitment and Assessment has never been easier.</h1>
      </div>
      <div className={classes.sectionCards}>
        {
          revolutionizedConductingExams.map((card, index) => {
            return <Card style={{ margin: '0.5rem' }} key={index} title={card.title} content={card.content} />
          })
        }

      </div>
      <a href="#demo">
        <PrimaryButton style={{ width: '16.313rem', fontSize: "1rem", marginTop: "4rem" }} >Request a Demo</PrimaryButton>
      </a>
    </div>
    <div className={classes.section5}>
      <div className={classes.section5BackgroundImage}>
      </div>
      <div className={classes.section5Content}>
        <div className={classes.section5Heading}>
          <h1 className={classes.sec5headingText}>
            We have revolutionized the process of conducting exams</h1>
        </div>

        <div className={classes.sectionCards}>
          {
            recruitmentAndAssessment.map((card, index) => {
              return <Card style={{ margin: '0.5rem' }} key={index} title={card.title} content={card.content} />
            })
          }
        </div>
      </div>
    </div>
    <div className={classes.section7}>
      <WeCertifiedUnder style={{ background: 'var(--selection-sectionColor)' }} />
      <div className={classes.section7BottomContainer}>
        <h1 className={classes.headingText} >Your Reasons to trust us</h1>
        <p className={classes.contentPara} style={{ maxWidth: '50rem', textAlign: 'center' }}>Easy to use and fully customizable features tailored just for you. With our vast experience and customer base, our understanding of your needs is unparalleled.</p>
        <div className={classes.sectionCards}>
          <CircleCard image={AkashLogo} />
          <CircleCard image={CareerPointLogo} />
          <CircleCard image={FiitJeeLogo} />
          <CircleCard image={ResonanceLogo} />
          <CircleCard image={physicsWallah} />
        </div>
      </div>
    </div>
    <div className={classes.section8} id='demo'>
      <div className={classes.section8LeftContainer}>
        <h1 className={classes.headingText}>Enquiry Form</h1>
        <form className={classes.contactForm}>
          <input type="text" className={classes.nameInput} onChange={(e) => setName(e.target.value)} placeholder='Name' />
          <input type="email" className={classes.emailInput} onChange={(e) => setEmail(e.target.value)} placeholder='Email Address' />
          <div className={classes.numberInput}>
            <select name="countryCode" id="countryCode" onChange={e => setCountry(e.target.value)}>
              <option value="">Country</option>
              {
                countryCode?.map(country => {
                  return <option value={country.name}>{country.name}</option>
                })
              }
            </select>
            <input type="number" className={classes.numberInput} onChange={(e) => setNumber(e.target.value)} placeholder='Phone Number' />
          </div>
          <input type="text" className={classes.nameInput} onChange={e => setLocation(e.target.value)} placeholder='Location' />
          <textarea type="text" className={classes.enquiryInput} onChange={(e) => setEnquiry(e.target.value)} placeholder='Enquiry For?' />
          <span className={`${classes.formMessage} ${isError ? classes.error : classes.success}`}>{formMessage}</span>
          <PrimaryButton
            type={'button'}
            style={{ width: '27rem', margin: '1rem 0', fontSize: '0.8rem' }}
            onClick={enquirySubmit}
          >Submit</PrimaryButton>
        </form>
      </div>
      <div className={classes.section8RightContainer}>
          <img src={backgroundImage} alt='backgroundImage'/>
      </div>
    </div>
   {/* {landingPopUP && <LandingPopUp onClose={() => setLandingPopUp(false)}/>} */}
  </div>
}

export default Homescreen;

{/* <div className={classes.section6}>
      <div className={classes.section6VideoContainer}>
        <img src={handShakeImage1} className={classes.section6Video} alt="video" />
        <button className={classes.videoPlayButton}><img src={play} alt="play" /></button>
      </div>
      <div className={classes.section6HeadingContainer}>
        <h1 className={classes.headingText}>Heading2</h1>
        <p className={classes.contentPara}>Related Promotional Text</p>
      </div>
    </div> */}