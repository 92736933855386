import React from 'react'
import classes from './Reporting.module.css'
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import liveProctoring from '../../assets/features/ai remote proctoring- live proctoring.jpg';
import automatedProctoring from '../../assets/features/AI remote proctoring- auto proctoring.jpg';
import screenProctoring from '../../assets/features/ai remote proctoring - screen monitoring.jpg';
import browserLockdown from '../../assets/features/AI remote proctoring - browser lockdown.png'

function Proctoring() {
    return <div className={classes.main}>
        <QuestionBank
            title={'AI Based Remote Proctoring'}
            content={"Remote proctoring systems prioritize data privacy and security. They ensure that video feeds, audio feeds, and any personal information collected during the monitoring process are encrypted and stored securely."} />
        <OurVisionAndMission
            title={'Live Proctoring'}
            image={liveProctoring}
            content={"Live proctoring involves a proctor monitoring the candidates in real-time through video and audio feeds. Proctors can observe candidates' behaviors, such as body movements, eye movements, and surroundings, to detect any suspicious activities or violations of exam rules."}
        />
        <OurVisionAndMission
            title={'Automated Proctoring'}
            image={automatedProctoring}
            content={"Automated proctoring uses AI and machine learning algorithms to monitor candidates during the exam. It analyzes data such as candidate's facial expressions, eye movements, and browser activities to identify any irregularities or potential cheating behaviors."}
            right
        />
        <OurVisionAndMission
            title={'Screen Monitoring'}
            image={screenProctoring}
            content={"Remote proctoring software can monitor candidates' computer screens to detect any unauthorized activities. It can flag instances of switching applications, opening new tabs, or accessing prohibited resources during the exam."}
        />
        <OurVisionAndMission
            title={'Browser Lockdown'}
            image={browserLockdown}
            content={"Remote proctoring software can restrict candidates' access to external websites or applications during the exam by locking down their browsers. This prevents them from searching for answers or accessing unauthorized resources."}
            right
        />
    </div>

}

export default Proctoring