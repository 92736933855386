import React from 'react'
import classes from './QuestionBankScreen.module.css'
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import resultManageMent from '../../assets/features/candidate admin portal - result management.png';
import testTaking from '../../assets/features/Candidate admin portal- test taking.png';
import testCreation from '../../assets/features/CAP - test creation and management.jpg';
import questionBankManagement from '../../assets/features/CAP - question bank management.jpg';
import testEnrollment from '../../assets/features/CAP - test enrollment.jpg'
import resultAccess from '../../assets/features/CAP- result access.jpg'

function Portal() {
    return <div className={classes.main}>
        <QuestionBank
            title={'Admin Portal'}
            content={'User Management: The admin portal allows administrators to manage user accounts, including creating new accounts, editing user information, and deactivating or deleting accounts.'}/>
        <OurVisionAndMission
           title={'Test Creation and Management'}
           image={testCreation}
           content={"Admins can create, configure, and manage tests within the admin portal. This includes defining test parameters, setting time limits, specifying question formats, and organizing questions into sections or topics."}
        />
        <OurVisionAndMission
            title={'Question Bank Management'}
            image={questionBankManagement}
            content={'The admin portal provides tools for managing the question bank. Admins can add new questions, categorize them into subjects or topics, assign metadata and tags, review and validate questions, and update or remove outdated questions.'}
            right
        />
        <OurVisionAndMission
            title={'Result Management'}
            image={resultManageMent}
            content={"Admins can access and manage test results, generate reports, and analyze performance data. This includes viewing individual candidate scores, generating aggregate reports, and exporting data for further analysis."}
        />
        <QuestionBank
            title={'Candidate Portal'}
            // content={'User Management: The admin portal allows administrators to manage user accounts, including creating new accounts, editing user information, and deactivating or deleting accounts.'}
        />
        <OurVisionAndMission
           title={'Test Enrollment'}
           image={testEnrollment}
           content={"Candidates can view available tests, enroll in specific tests, and access the test instructions and guidelines provided by the admin."}
        />
        <OurVisionAndMission
            title={'Test-taking'}
            image={testTaking}
            content={'The candidate portal allows candidates to take the tests assigned to them within the specified time window. It provides a user-friendly interface for answering questions, navigating through the test, and submitting their responses.'}
            right
        />
        <OurVisionAndMission
            title={'Result Access'}
            image={resultAccess}
            content={"After completing a test, candidates can view their results, including scores and performance feedback, through the candidate portal."}
        />
    </div>
}

export default Portal;