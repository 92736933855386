import React from 'react'
import classes from './Monetize.module.css'
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import testFees from '../../assets/features/monetise - test fees.png'
import paymentGatewayIntegration from '../../assets/features/monetize- payment gateway.jpg'

function Monetize() {
    return <div className={classes.main}>
        <QuestionBank
            title={'Monetize'}
            content={"Monetizing an online examination portal involves implementing strategies to generate revenue from the services provided by the portal."} />
        <OurVisionAndMission
            title={'Test Fees'}
            image={testFees}
            content={"Charging test fees from the candidates who wish to participate in the exams offered through the portal. Candidates are required to pay a fee for each test they take, which covers the cost of administering and delivering the assessment."}
        />
        <OurVisionAndMission
            title={'Payment Gateway Integration'}
            image={paymentGatewayIntegration}
            content={"Choose a reliable and secure payment gateway provider that aligns with your business requirements."}
            right
        />
    </div>

}

export default Monetize