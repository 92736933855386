import { Route, Routes } from 'react-router';
import './App.css';
import Footer from './App/components/Footer/Footer';
import GetInTouch from './App/components/GetInTouch/GetInTouch';
import Header from './App/components/Header/Header';
import AboutUs from './App/modules/AboutUs/AboutUs';
import ContactUs from './App/modules/ContactUs/ContactUs';
import FeaturesScreen from './App/modules/FeaturesScreen/FeaturesScreen';
import HomeScreen from './App/modules/HomeScreen/HomeScreen';
import PricingScreen from './App/modules/PricingScreen/PricingScreen';
import { BrowserRouter } from 'react-router-dom';
import QuestionBankScreen from './App/modules/FeaturesScreen/QuestionBankScreen';
import DescriptiveTest from './App/modules/FeaturesScreen/DescriptiveTest';
import TestCreation from './App/modules/FeaturesScreen/TestCreation';
import Portal from './App/modules/FeaturesScreen/Portal';
import ReportingTool from './App/modules/FeaturesScreen/ReportingTool';
import MoblieApplication from './App/modules/FeaturesScreen/MobileApplication';
import Proctoring from './App/modules/FeaturesScreen/Proctoring';
import Monetize from './App/modules/FeaturesScreen/Monetize';
import Security from './App/modules/FeaturesScreen/Security';
import Corporate from './App/modules/Corporate/Corporate';
import Education from './App/modules/Education/Education';
import Clients from './App/modules/Clients/Clients';
import TermsAndConditions from './App/modules/Common/TermsAndConditions';
import PrivacyPolicy from './App/modules/Common/PrivacyPolicy';
import Refunds from './App/modules/Common/Refunds';
function App() {
  return (
    <div className="App">
      <Header />
      <GetInTouch />
      <BrowserRouter>
        <Routes>
          <Route exact path='/' Component={HomeScreen} />
          <Route exact path='/aboutus' Component={AboutUs} />
          <Route exact path='/features' Component={FeaturesScreen} />
          <Route exact path='/pricing' Component={PricingScreen} />
          <Route exact path='/contactus' Component={ContactUs} />
          <Route exact path='/corporate' Component={Corporate} />
          <Route exact path='/education' Component={Education} />
          <Route exact path='/clients' Component={Clients} />

          <Route exact path='/question-bank' Component={QuestionBankScreen} />
          <Route exact path='/test-creation' Component={TestCreation} />
          <Route exact path='/portal' Component={Portal} />
          <Route exact path='/reporting-tool' Component={ReportingTool} />
          <Route exact path='/mobile-application' Component={MoblieApplication} />
          <Route exact path='/proctoring' Component={Proctoring} />
          <Route exact path='/monetize' Component={Monetize} />
          <Route exact path='/descriptive-test' Component={DescriptiveTest} />
          <Route exact path='/security' Component={Security} />
          <Route exact path='/terms-and-conditions' Component={TermsAndConditions} />
          <Route exact path='/privacy-policy' Component={PrivacyPolicy} />
          <Route exact path='/refunds' Component={Refunds} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
