import React from 'react'
import classes from './Secondary.module.css'
import { getClasses } from '../../../utils/utils'

function SecondaryButton({children, style, className, onClick}) {
  return (
    <button 
    className={getClasses(classes.secondaryBtn, className)}
    style={style}
    onClick={onClick}
    >{children}</button>
  )
}

export default SecondaryButton