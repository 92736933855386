import React from 'react'
import classes from './OurVisionAndMission.module.css'
import Laptop from '../../assets/homescreen/laptopImage.png';
import handShakeImage1 from '../../assets/homescreen/handShake1.png';
import handShakeImage2 from '../../assets/homescreen/handShake2.png';

function OurVisonAndMission({ style, title, content, id, innerRef , image, right}) {
    return <div className={right ? classes.rightMain : classes.main } id={id} ref={innerRef} style={style}>
        <div className={right ? classes.leftContainerTextAlignRight :classes.leftContainer}>
            <h1 className={classes.headingText}>{title}</h1>
            <div className={classes.contentPara}>{content}</div>
        </div>
        <div className={classes.rightContainer}>
            <img src={image} alt="image" />
        </div>
    </div>
}

export default OurVisonAndMission