import React from 'react'
import classes from './Footer.module.css'
import facebookIcon from "../../assets/social/facebook.svg";
import youtubeIcon from "../../assets/social/youtube.svg";
import instaIcon from "../../assets/social/instagram.svg";
import twitter from '../../assets/social/twitter.svg'
import linkedIn from '../../assets/social/linked-in.svg'
import { contact } from '../../utils/utils';

function Footer() {
    return (
        <footer className={classes.mainContainer}>
            <div className={classes.detailsBox}>
                <div className={classes.productBox}>
                    <div className={classes.productBoxLogoAndContent}>
                        <div>
                        <div className={classes.evalExamsLogo} >
                            <h2 className={classes.logo}>EvalExam</h2>
                            <div className={classes.options}>
                                <div className={classes.phone}>
                                    <p>Call us at</p>
                                    <p className={classes.phoneNumber}>
                                        <a href={`tel:${contact.phone}`}>
                                        {contact.phone}
                                        </a>
                                    </p>
                                </div>
                                <div className={classes.email}>
                                    <p>Mail us at</p>
                                    <p className={classes.mailAdd}>
                                        <a href={`mailto:${contact.mail}`}>
                                        {contact.mail}
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.socialBox}>
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/virsoftech.official/" target="_blank" alt="facebook">
                                        <img src={facebookIcon} className={classes.socialLinksImage} alt="Facebook" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://youtu.be/PqeB1ChycDM" target="_blank" alt="youtube">
                                        <img src={youtubeIcon} className={classes.socialLinksImage} alt="Youtube" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/virsoftech/" target="_blank" alt="instagram">
                                        <img src={instaIcon} className={classes.socialLinksImage} alt="Instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/virsoftech.official/" target="_blank" alt="facebook">
                                        <img src={twitter} className={classes.socialLinksImage} alt="twitter" />
                                    </a>
                                </li>
                                <li>
                                <a href="https://www.linkedin.com/company/virsoftech/about/" target="_blank" alt="facebook">
                                    <img src={linkedIn} className={classes.socialLinksImage} alt="linkedIn" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        </div>
                    </div>
                </div>
                <div className={classes.exploreBox}>
                    <h3>Features</h3>
                    <ul>
                        <li><a href="/test-creation">Test creation</a></li>
                        <li><a href="/question-bank">Question bank management</a></li>
                        <li> <a href="/portal">Candidate and admin portal </a></li>
                        <li><a href="/reporting-tool">Reporting tool </a></li>
                        <li><a href="/mobile-application">Mobile application</a></li>
                        <li><a href="/proctoring">AI remote proctoring </a></li>
                        <li><a href="/monetize">Monetize </a></li>
                        <li><a href="/descriptive-test">Descriptive test</a></li>
                        <li><a href="/security">Security </a></li>
                    </ul>
                </div>
                <div className={classes.quickLinksBox}>
                    <h3>Education</h3>
                    <ul>
                        <li><a href="/education#assessments">Assessment for education</a></li>
                        {/* <li><a href="/education">Assessment for school</a></li> */}
                        <li><a href="/education#examManagement">Entrance exam management</a></li>
                        <li><a href="/education">Online examination solutions</a></li>
                        <li><a href="/education">Center based assessment</a></li>
                        <li><a href="/education">Question paper generation</a></li>
                        <li><a href="/education#remoteInvigilation">Remote proctoring</a></li>
                        <li><a href="/education#descriptiveTest">Descriptive test</a></li>
                    </ul>
                </div>
                <div className={classes.contactUsBox}>
                    <div className={classes.upperContent}>
                        <h3>Corporate</h3>
                        <ul>
                            <li><a href="/corporate">Assessment for corporate</a></li>
                            <li><a href="/corporate#skillAssessments">Employee skill assessment</a></li>
                            <li><a href="/corporate#recruitmentAssessment">Recruitment assessment</a></li>
                            <li><a href="/corporate#remoteProctoring">Remote proctoring</a></li>
                            <li><a href="/corporate#campusRecruitment">Campus recruitment solution </a></li>
                        </ul>
                    </div>
                </div>
                <div className={classes.aboutUsBox}>
                    <div>
                        <h3>Industry</h3>
                        <ul>
                            <li><a href="/education">Education</a></li>
                            <li><a href="/corporate">Corporate</a></li>
                            <li><a>Government</a></li>
                        </ul>
                    </div>
                    <div className={classes.upperContent}>
                        <h3>About Us</h3>
                        <ul>
                            <li><a href='/aboutus'>About eVAL Exams</a></li>
                            <li><a href="/contactus">Contact us</a></li>
                            <li><a href="/clients">Clients</a></li>
                            {/* <li>FAQs</li> */}
                        </ul>
                    </div>
                </div>
            </div>
            <div className={classes.copyrightBox}>
                <p>© 2023 EvalExam | All Rights Reserved</p>
                <ul>
                    <li><a href='/privacy-policy' style={{color:'#ffffff'}}>Privacy Policy</a></li>
                    <li><a href='/terms-and-conditions' style={{color:'#ffffff'}}>Terms of services</a></li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer