import React from 'react';
import classes from './Security.module.css';
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import secureDateTransmission from '../../assets/features/security- secure data transmission.jpg';
import dataPrivacy from '../../assets/features/security - data privacy.jpg';
import regularAudits from '../../assets/features/Security - regular audits.png'

function Security() {
    return <div className={classes.main}>
        <QuestionBank
            title={'Security'}
            content={'Our platform provides a seamless and secure examination experience, protecting sensitive data at all times. We employ industry-standard SSL encryption to safeguard all data and ensure data privacy.'} />
        <OurVisionAndMission
            title={'Secure Data Transmission'}
            image={secureDateTransmission}
            content={"We encrypt all the data transmitted between the user’s browser and our examination portal using SSL/TSL protocols. This ensures that the sensitive information such as login credentials and test responses is protected from unauthorized interception and tampering. "}
        />
        <OurVisionAndMission
            image={dataPrivacy}
            title={'Data Privacy'}
            content={"We comply with relevant data protection regulations to ensure that we give utmost privacy when it comes to user data. Our implementation of appropriate data storage, retention and disposal practices safeguards personal information and test results for our clients."}
            right
        />
        <OurVisionAndMission
            image={regularAudits}
            title={'Regular audits'}
            content={"We conduct regular security audits and monitoring of the online examination portal to identify and address potential vulnerabilities or security incidents promptly. We implement intrusion detection systems, log analysis, and anomaly detection to detect and respond to security threats."}
        />
    </div>
}

export default Security