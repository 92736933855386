import React from 'react'
import classes from './DescriptiveTest.module.css'
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import timeLimit from '../../assets/features/Descriptive test - time limit.jpg';
import questionFormat from '../../assets/features/descriptive test- question format.jpg'
import wordLimit from '../../assets/features/descriptive test - word limit.png'
import evaluationProcess from '../../assets/features/descriptive test - evaluation process.jpg'

function DescriptiveTest() {
    return <div className={classes.main}>
        <QuestionBank title={'Descriptive test'} content={'Steps to incorporate descriptive tests into an online examination portal'}/>
        <OurVisionAndMission
            title={'Question Format'}
            image={questionFormat}
            content={"Design questions and prompts that require candidates to write detailed answers or essays. The candidatescan either write an answer manually or upload a document."}
        />
        <OurVisionAndMission
            title={'Time Limit'}
            image={timeLimit}
            content={"Set a specific time limit for completing the descriptive test. This ensures that candidates manage their time effectively and provides a standardized framework for evaluating their responses."}
            right
        />
        <OurVisionAndMission
            title={'Word Limit'}
            image={wordLimit}
            content={"Define a word limit for each question or prompt to guide candidates on the expected length of their responses. This helps to ensure fairness and consistency in the evaluation process."}
        />
        <OurVisionAndMission
            title={'Evaluation Process'}
            image={evaluationProcess}
            content={"Assign qualified evaluators and subject matter experts to review and assess the candidates' responses. Depending on the volume of tests, you may consider manual evaluation or opt for automated systems to analyze candidate response."}
            right
        />
    </div>

}

export default DescriptiveTest