import React from 'react';
import classes from './QuestionBankScreen.module.css';
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import createCategoriesOrSubjects from '../../assets/features/question bank management- create categories.jpg';
import defineQuestionTypes from '../../assets/features/question bank man- define question type.jpg'
import inputQuestions from '../../assets/features/question bank man- import question.jpg'
import updateAndMaintainTheQuestionBank from '../../assets/features/question bank manage- update and maintain question bank.png'


function QuestionBankScreen() {
    return <div className={classes.main}>
        <QuestionBank
            title={'Question Bank Management'}
            content={'Managing a question bank in an online examination portal involves organizing, storing, and maintaining a collection of test questions for easy retrieval and use in assessments.'}/>
        <OurVisionAndMission
            title={'Create Categories or Subjects'}
            image={createCategoriesOrSubjects}
            content={"Begin by categorizing the questions into relevant subjects or topics. This helps in organizing the question bank and makes it easier to search for specific questions later."}
        />
        <OurVisionAndMission
            title={'Define Question Types'}
            image={defineQuestionTypes}
            content={"Identify and define the different question types that will be included in the question bank, such as multiple-choice, true/false, fill-in-the-blank, matching, or essay questions. Each question type should have its own template or format."}
            right
        />
        <OurVisionAndMission
            title={'Input Questions'}
            image={inputQuestions}
            content={"Enter the questions into the question bank, using the appropriate question template for each question type. Include the question content, options (if applicable), correct answers, and any additional information or explanations."}
        />
        <OurVisionAndMission
            title={'Update and Maintain the Question Bank'}
            image={updateAndMaintainTheQuestionBank}
            content={"Continuously update and maintain the question bank by adding new questions, revising existing questions, or archiving outdated or irrelevant questions. Regularly review the metadata, tags, and attributes to ensure they remain accurate and up to date."}
            right
        />
    </div>
}

export default QuestionBankScreen