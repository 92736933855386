import React from 'react'
import classes from './VarietyCard.module.css'

function VarietyCard({ image, title }) {
    return <div className={classes.card}>
        <img src={image} alt="Variety Image" />
        <h3 className={classes.varietyTitle}>{title}</h3>
    </div>
}

export default VarietyCard