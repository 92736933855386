import React from 'react'
import classes from './TestCreation.module.css';
import selectTestFormat from '../../assets/features/test creation - select test format.jpg';
import addQuestion from '../../assets/features/test creation - add questions.jpg';
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import configureTestSettings from '../../assets/features/test creation - configure test settings.jpg';
import publishAndAssign from '../../assets/features/test creation - publish and assign.jpg'
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission'

function TestCreation() {
    return <div className={classes.main}>
        <QuestionBank title={'Test Creation'} content={'A general framework for creating a test within an online examination portal'} />
        <OurVisionAndMission
            title={'Select Test Format'}
            image={selectTestFormat}
            content={"Determine the basic parameters of the test, such as the test name, duration, and any specific instructions or guidelines for test-takers, including the question types you want to include. Common question types include multiple-choice, true/false, fill-in-the-blank, matching, and essay questions."}
        />
        <OurVisionAndMission
            title={'Add Question'}
            image={addQuestion}
            content={"You have the options to manually enter questions or import them from a question bank or MS Word. Enter the question content, options, correct answers, and any relevant explanations or feedback."}
            right
        />
        <OurVisionAndMission
            title={'Configure Test Settings'}
            image={configureTestSettings}
            content={"Set general test settings, including the test availability window (start and end dates), time limits for the entire test, test navigation options, and any additional instructions or guidelines for test-takers."}
        />
        <OurVisionAndMission
            title={'Publish and Assign'}
            image={publishAndAssign}
            content={"Once you are satisfied with the test setup, publish the test within the online examination portal. Assign the test to the intended test-takers or groups, ensuring they receive appropriate notifications and access instructions."}
            right
        />
    </div>

}

export default TestCreation