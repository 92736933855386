import React from 'react'
import classes from './Section1A.module.css'
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton'
import sec1AImage from '../../assets/homescreen/section1AImage.svg'
import PrimaryButton from '../Buttons/PrimryButton/PrimaryButton'

function Section1A() {
  return (
    <div className={classes.section1A}>
      <div className={classes.leftContainer}>
        <h1 className={classes.leftHeading}>Effortless Exam Management & Innovative Assessment Solutions</h1>
        <p className={classes.leftPara}>
        Streamline and optimize testing solutions & management and experience the future of test conducting software.
        </p>
        <div className={classes.buttons}>
          <a href="/contactus"><PrimaryButton style={{ width: "10.68rem", fontSize: "1.2rem" }} >Contact Us</PrimaryButton></a>
          <a href="/#demo"><SecondaryButton style={{ width: "10.68rem", fontSize: "1.2rem" }}>Free trial</SecondaryButton></a>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <div className={classes.darkBlue}></div>
        <div className={classes.aquaBlue}></div>
        <img src={sec1AImage} alt="section1a" />
      </div>
    </div>
  )
}

export default Section1A