import React from 'react'
import classes from './PersonCard.module.css'

function PersonCard({title, designation, profilePicture}) {
    return <div className={classes.card}>
        <img src={profilePicture} alt="profile picture" />
        <div className={classes.cardDetails}>
            <h3 className={classes.userName}>{title}</h3>
            <span className={classes.userRole}>{designation}</span>
        </div>
    </div>

}

export default PersonCard