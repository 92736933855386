import React, { Children } from 'react'
import classes from './QuestionBank.module.css'
import QuestionBankImg from '../../assets/features/questionBankImage.svg'
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton'

function QuestionBank({ title, content }) {
    return <div className={classes.questionBank}>
        <div className={classes.leftContainer}>
            <h1 className={classes.headingText}>{title}</h1>
            <p className={classes.contentPara}>
                {content}
            </p>
            <div>
                <a href="/contactus"><SecondaryButton >Get Free trial</SecondaryButton></a>
            </div>
        </div>
        <div className={classes.rightContainer}>
            <img src={QuestionBankImg} alt="Question Bank Image" />
        </div>
    </div>
}

export default QuestionBank