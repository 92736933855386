import React from 'react'
import classes from './Clients.module.css'
import CircleCard from '../../components/Cards/CircleCard'
import AkashLogo from '../../assets/clients/AakashInstitute.png';
import CareerPointLogo from '../../assets/clients/Careerpoint.png';
import FiitJeeLogo from '../../assets/clients/FIITJEE.jpg';
import ResonanceLogo from '../../assets/clients/Resonance.png'
import Amity from '../../assets/clients/amity-university.png';
import banasthali from '../../assets/clients/banasthali_university.jpg';
import IIM from '../../assets/clients/IIM.png';
import narayana from '../../assets/clients/narayana-new-logo.png';

function Clients() {
  return (
    <div className={classes.main}>
        <div className={classes.container}>
        <h1 className={classes.headingText} >Our clients</h1>
        <p className={classes.contentPara}>Easy to use and fully customizable features tailored just for you. With our vast experience and customer base, our understanding of your needs is unparalleled.</p>
        <div className={classes.sectionCards}>
          <CircleCard image={AkashLogo} />
          <CircleCard image={CareerPointLogo} />
          <CircleCard image={FiitJeeLogo} />
          <CircleCard image={ResonanceLogo} />
          <CircleCard image={Amity} />
          <CircleCard image={banasthali} />
          <CircleCard image={IIM} />
          <CircleCard image={narayana} />
        </div>
      </div>
    </div>
  )
}

export default Clients