import React, { useState } from 'react'
import classes from './Header.module.css'
import SecondaryButton from '../Buttons/SecondaryButton/SecondaryButton';
import logo from '../../assets/logo.png';
import { faBars, faChevronDown, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Header() {
    const [expanded, setExpanded] = useState(false)

    return (
        <div className={classes.main}>
            <div className={classes.logoContainer}>
                <a href='/' className={classes.logoImage}><img src={logo} alt="Logo" /></a>
            </div>

            <button className={classes.hamBurgerMenu} onClick={() => { setExpanded(!expanded) }}>
                {expanded ? <FontAwesomeIcon icon={faXmark} /> : <FontAwesomeIcon icon={faBars} />}
            </button>
            <div className={expanded ? classes.navbarAndButtonsExtended : classes.navbarAndButtons}>
                <ul className={classes.navMenu}>
                    <li className={classes.navMenuItem}> <a href="/aboutus" className={classes.links}>About us</a></li>
                    <li className={classes.navMenuItem}><a href="/features" className={classes.links}>Features</a>
                        <div className={classes.dropButton}> <FontAwesomeIcon icon={faChevronDown} /></div>
                        <div className={classes.featuresDropdown}>
                            <div className={classes.dropDownContent}>
                                <a className={classes.submenuLink} href="/test-creation">Test creation</a>
                                <a className={classes.submenuLink} href="/question-bank">Question bank management</a>
                                <a className={classes.submenuLink} href="/portal">Candidate and admin portal </a>
                                <a className={classes.submenuLink} href="/reporting-tool">Reporting tool </a>
                                <a className={classes.submenuLink} href="/mobile-application">Mobile application</a>
                                <a className={classes.submenuLink} href="/proctoring">AI remote proctoring </a>
                                <a className={classes.submenuLink} href="/monetize">Monetize </a>
                                <a className={classes.submenuLink} href="/descriptive-test">Descriptive test</a>
                                <a className={classes.submenuLink} href="/security">Security </a>
                            </div>
                        </div>
                    </li>
                    <li className={classes.navMenuItem}><a href="/education" className={classes.links}>Education</a>
                        <div className={classes.dropButton}><FontAwesomeIcon icon={faChevronDown} /></div>
                        <div className={classes.featuresDropdown}>
                            <div className={classes.dropDownContent}>
                        
				            <a href="/education#assessments">Assessment for educational institutes </a>
                                <a className={classes.submenuLink} href="/education#examManagement">Entrance exam management </a>
                                <a className={classes.submenuLink} href="/education">Online examination solutions</a>
                                <a className={classes.submenuLink} href="/education#remoteInvigilation">Remote proctoring</a>
                                {/* <a className={classes.submenuLink} href="javascript:;">Learning management system</a>
                                <a className={classes.submenuLink} href="javascript:;">School olympiad test</a> */}
                                <a className={classes.submenuLink} href="/education#descriptiveTest">Descriptive test</a>

                            </div>
                        </div>
                    </li>
                    <li className={classes.navMenuItem}><a href="/corporate" className={classes.links}>Corporate</a>
                        <div className={classes.dropButton}><FontAwesomeIcon icon={faChevronDown} /></div>
                        <div className={classes.featuresDropdown}>
                            <div className={classes.dropDownContent}>
                                <a className={classes.submenuLink} href="/corporate">Assessment for corporate </a>
                                <a className={classes.submenuLink} href="/corporate#skillAssessments">Skill assessments</a>
                                <a className={classes.submenuLink} href="/corporate#recruitmentAssessment">Recruitment assessment</a>
                                <a className={classes.submenuLink} href="/corporate#remoteProctoring">Remote proctoring</a>
                                {/* <a className={classes.submenuLink} href="javascript:;">Campus recruitment solution </a> */}
                            </div>
                        </div>
                    </li>
                    <li className={classes.navMenuItem}><a href="/clients" className={classes.links}>Clients</a></li>
                    <li className={classes.navMenuItem}><a href="/pricing" className={classes.links}>Pricing</a></li>
                    <li className={classes.navMenuItem}><a href="/contactus" className={classes.links}>Contact us</a></li>
                </ul>
                <div className={classes.buttonContainer}>
                    <a href="/#demo"><SecondaryButton >Request Trial</SecondaryButton></a>
                </div>
            </div>
        </div>
    )
}

export default Header