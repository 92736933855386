import React, { useState } from 'react'
import classes from './PricingScreen.module.css'
import pricingScreenBanner from '../../assets/pricing/pricingScreenBanner.svg'
import SecondaryButton from '../../components/Buttons/SecondaryButton/SecondaryButton'
import PlanCard from '../../components/Cards/PlanCard'
import Table from '../../components/table/Table'

function PricingScreen() {
    const [isActive, setIsActive] = useState(true);

    return <div className={classes.main}>
        <div className={classes.pricingBanner}>
            <h1 className={classes.bannerheading}>We have got the best pricing for you !</h1>
            <img src={pricingScreenBanner} className={classes.bannerImage} alt="Banner IMage" />
        </div>
        <div className={classes.pricingPlan}>
            <div className={classes.upperContainer}>
                <h1 className={classes.headingText}>Choose a plan that's right for you</h1>
                <p className={classes.contentPara} >
                    Our flexible pricing options include a robust, scalable and feature rich platform. We got all your needs covered with plans that suit your requirements.
                </p>
            </div>
            <div className={classes.bottomContainer}>
                <PlanCard
                    PlaneHeading={'Free'}
                    planeContent={'Get started with creating your question banks and conduct tests with our free module.'}
                />
                <PlanCard
                    PlaneHeading={'Basic'}
                    planeContent={'Conduct tests for up to 500 students and secure your tests with advanced proctoring features.'}
                />
                <PlanCard
                    PlaneHeading={'Business'}
                    planeContent={'Get exclusive features like white labeling and use the mobile applications for both android and iOS devices.'}
                />
                <PlanCard
                    PlaneHeading={'Enterprises'}
                    planeContent={'Create tests for unlimited users and make sure that the features are specific to your requirements by customizing them.'}
                />
            </div>
        </div>
        <div className={classes.pricingButtons}>
            <SecondaryButton className={isActive === true ? classes.activeButton : classes.unActiveButton} onClick={()=>{setIsActive(true)}}>Test Based</SecondaryButton>
            <SecondaryButton className={isActive === false ? classes.activeButton : classes.unActiveButton} onClick={()=>{setIsActive(false)}}>User Based</SecondaryButton>
        </div>
        <div className={classes.pricingTable}>
            <div className={classes.tableContainer} >
                <h1 className={classes.headingText} style={{ fontSize: '2rem', width: '100%', padding: '1rem' }}>Feature Comparison</h1>
                {
                    isActive=== false ? 
                    <Table numberOfUser={'No. of Users'} free={30}  basic={`100-500`} business={`> 500`} enterprise={'Unlimited'}/> :
                    <Table numberOfUser={'No. of Test'} free={30}  basic={`100 - 10,000`} business={'> 10,001'} enterprise={'Unlimited'}/>
                }
            </div>
        </div>
    </div>
}

export default PricingScreen