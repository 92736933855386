import React from 'react'
import classes from './SlidingButtons.module.css'
import { faArrowLeft ,faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function SlidingButtons({style, onNext, onPrevious}) {
  return < div className={classes.SlidingButtons} style={style}>
    <div className={classes.btnContainer} onClick={onPrevious}>
    <FontAwesomeIcon icon={faArrowLeft} />
    </div>
    <div className={classes.btnContainer} onClick={onNext}>
    <FontAwesomeIcon icon={faArrowRight} />
    </div>
    {/* <button className={classes.leftBtn}><HiOutlineArrowNarrowLeft /></button>
    <button className={classes.rightBtn}><HiOutlineArrowNarrowRight /></button> */}
  </div>
  
}

export default SlidingButtons