import React from 'react'
import classes from './FeaturesScreen.module.css'
import SecondaryButton from '../../components/Buttons/SecondaryButton/SecondaryButton';
import VarietyCard from '../../components/Cards/VarietyCard';
import objectiveAssignmentImage from '../../assets/features/objectiveQuestions.png'
import audioAssignment from '../../assets/features/audioAssignment.png';
import videAssignment from '../../assets/features/videoAssignment.png';
import codingAssignment from '../../assets/features/codingAssignments.png';
import imageBased from '../../assets/features/imageAssignments.png';
import DescriptiveAssignment from '../../assets/features/descritiveAssignments.png';
import howToUpload from '../../assets/features/howToUpload.png'
import ReviewCard from '../../components/Cards/ReviewCard';
import userImage from '../../assets/aboutus/image 61@2x.png'
import QuestionBank from '../../components/QuestionBank/QuestionBank';


function FeaturesScreen() {
    return <div className={classes.main}>
        <QuestionBank title={'Question Bank'} content={'Bulk upload your question papers and simplify assessment creation'} />
        <div className={classes.varietyOfQuestion}>
            <div className={classes.varietyUpperContainer}>
                <h1 className={classes.headingText}>Upload a Variety of Question Types</h1>
                <p className={classes.contentPara} >
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
            </div>
            <div className={classes.varietyBottomContainer}>
                <VarietyCard image={objectiveAssignmentImage} title={'Objective Type Questions'} />
                <VarietyCard image={audioAssignment} title={'Audio Assignments'} />
                <VarietyCard image={videAssignment} title={'Video assignments'} />
                <VarietyCard image={codingAssignment} title={'Coding assignments'} />
                <VarietyCard image={imageBased} title={'Image based Questions'} />
                <VarietyCard image={DescriptiveAssignment} title={'Descriptive Type Questions'} />
            </div>
        </div>
        <div className={classes.howToUpload}>
            <h1 className={classes.headingText}>See how to Upload Question papers</h1>
            <img src={howToUpload} className={classes.howToUploadImage} alt="howToupload" />
        </div>
        <div className={classes.specialtyTagline}>
            <h1 className={classes.headingText}>A Single platform to conduct all exams securely</h1>
            <a href="/contactus"><SecondaryButton style={{ width: "10.68rem", fontSize: "1rem", margin: '2rem 0' }}>Request Demo</SecondaryButton></a>
        </div>
        <div className={classes.clientSuccessStories}>
            <h1 className={classes.headingText} style={{ textAlign: "center" }}>Client success stories</h1>
            <ReviewCard
                userImage={userImage}
                rating={4}
                content={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'}
                userName={'Testimonial Name'}
                userDetail={'Exam Name'}
            />
        </div>

    </div>
}

export default FeaturesScreen