import React from 'react'
import classes from './Common.module.css'
import { contact } from '../../utils/utils';

function PrivacyPolicy() {
    return (
        <div className={classes.main}>
            <div className={classes.ourStory}>
                <div className={classes.ourStoryLeft}>
                    <h1 className={classes.headingText}>Privacy Policy</h1>
                    <p className={classes.contentPara} >
                        Your privacy is important at the Eval Exam, so we&#39;ve developed a Privacy Policy that
                        covers how we collect, use, disclose, transfer, and store your information.</p>
                    <p className={classes.contentPara} >
                        Please take a moment to familiarize yourself with our privacy practices and contact us if
                        you have any questions. Collection and Use of Personal information data that can be
                        used to uniquely identify or contact a single person. You are asked to provide your
                        personal information to sign Up at Eval Exam. Here are some examples of the types of
                        personal information Eval Exam may collect and how we may use it.</p>
                </div>
                <div className={classes.ourStoryLeft}>
                    <h2 className={classes.subHeadingText}>HOW WE USE YOUR PERSONAL INFORMATION</h2>
                    <p className={classes.contentPara} >
                        The personal information we collect allows us to keep you posted on Eval Exam’s latest
                        product announcements, updates, and upcoming events. It also helps us to improve our
                        services and content.</p>
                    <p className={classes.contentPara} >
                        We also use personal information to help us develop, deliver, and improve our products,
                        services, and content. We may also use personal information for internal purposes such
                        as auditing, data analysis, and research to improve Eval Exam products, services, and
                        customer communications.</p>
                    <p className={classes.contentPara}>Disclosure to Third Parties that Eval Exam partners for conducting assessments for
                        them and Eval Exam may make certain personal information available of the user or
                        users provided by that customer to access the report, result, or any data that may be
                        used for recruitment, development, certification or any other similar insight. For
                        example, when you subscribe and activate your Eval Exam account, you authorize Eval
                        Exam and its carrier to exchange the information you provide during the activation
                        process to carry out the delivery service. If you are approved for service, your account
                        will be governed by Eval Exam and its delivery partners&#39; respective privacy policies.
                        Personal information will only be shared by Eval Exam to provide or improve our

                        products and services. It will not be shared with third parties for their marketing
                        purposes.</p>
                    <p className={classes.contentPara} >
                        It may be necessary by law, legal process, litigation, and/or requests from public and
                        governmental authorities within or outside your country of residence - for Eval Exam to
                        disclose your personal information. We may also disclose information about you if we
                        determine that for purposes of national security, law enforcement, or other issues of
                        public importance, disclosure is necessary or appropriate. Protection of Personal
                        Information Eval Exam takes precautions - including administrative, technical, and
                        physical measures - to safeguard your personal information against loss, theft, and
                        misuse, as well as against unauthorized access, disclosure, alteration, and destruction.</p>

                </div>

                <div className={classes.ourStoryLeft}>
                    <h2 className={classes.subHeadingText}>INTEGRITY AND RETENTION OF PERSONAL INFORMATION</h2>
                    <p className={classes.contentPara} >
                        Eval Exam makes it easy for you to keep your personal information accurate, complete,
                        and up to date. We will retain your personal information for the period necessary to fulfill
                        the purposes outlined in this Privacy Policy unless a longer retention period is required
                        or permitted by law. Our Company Wide Commitment to Your Privacy makes sure your
                        personal information is secure, we communicate our privacy and security guidelines to
                        our employees and strictly enforce privacy safeguards within the company by email or
                        updates by email of any change in the privacy policy.</p>
                </div>
                <div className={classes.ourStoryLeft}>
                    <h2 className={classes.subHeadingText}>COLLECTION AND USE OF YOUR PERSONAL INFORMATION</h2>
                    <p className={classes.contentPara} >
                        The remote proctoring solution may have the requirement to collect images or
                        information from you while offering distributed assessment services. With regard to
                        security, Personal Information from you while offering assessment services due to the

                        nature of the conducting secured and proctored assessment services being offered.
                        SPDI shall have the meaning in accordance with the Information Technology
                        (Reasonable security practices and procedures and sensitive personal data or
                        Information), With regard to security, we respect all information provided to us, and take
                        all reasonable steps towards protection of the same. We have implemented technology
                        and policies, with the objective of protecting your privacy from unauthorized access and
                        improper use, and periodically review the same. We maintain procedural safeguards to
                        protect the confidentiality and security of personally identifiable information transmitted
                        to us.</p>
                </div>


                <div className={classes.ourStoryLeft}>
                    <h2 className={classes.subHeadingText}>LAWS SPECIFIC TO INDIA</h2>
                    <p className={classes.contentPara} >
                        In the case where Eval Exam is an intermediary between the client and consumer.
                        Whereas a client herein is the agency that assigns work to Eval Exam to collect
                        personal information while registration for conducting an assessment on behalf of the
                        client for a certain test or a series of tests for a specific role or roles to be used for
                        client’s requirement. Under Section 79 of the IT Act 2000, intermediaries are exempt
                        from liability under the Act in respect of any third-party information, data, or
                        communication link made available or hosted by them. The storage and purge policy of
                        the data, information, or processing to be directed by the client. Clients are hereby
                        supposed to store data temporarily or permanently based on requirements and Eval
                        Exam is not liable for storing data indefinitely for any client or assessment.
                    </p>
                </div>

                <div className={classes.ourStoryLeft}>
                    <h2 className={classes.subHeadingText}>PRIVACY QUESTIONS</h2>
                    <p className={classes.contentPara} >
                        If you have questions or concerns about eVAL Exam Privacy Policy or data processing,
                        please contact us at :
                    </p>
                    <p className={classes.contentPara} >
                        <a href={`tel:${contact.phone}`}>
                            {contact.phone}
                        </a>
                    </p>
                    <p className={classes.contentPara} >
                        <a href={`mailto:${contact.mail}`}>
                            {contact.mail}
                        </a>
                    </p>
                </div>

            </div>
        </div>
    )
}

export default PrivacyPolicy