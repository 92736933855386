import React from 'react'
import classes from './WeCertifiedUnder.module.css'
import planday from '../../assets/homescreen/planday.svg';
import pearlFisher from '../../assets/homescreen/peralFisher.svg';
import umbraco from '../../assets/homescreen/umbraco.svg';
import brightPearl from '../../assets/homescreen/brightPearl.svg';
import cmmiLevel3 from '../../assets/certifications/cmmi-level-3-certification.jpg';
import ISO9001 from '../../assets/certifications/ISO 9001.jpg';
import ISO14001 from '../../assets/certifications/ISO 14001.jpg';
import ISO20000 from '../../assets/certifications/ISO 20000.webp';
import ISO27001 from '../../assets/certifications/ISO 27001.png'

function WeCertifiedUnder({ title, style }) {
    return <div className={classes.main} style={style}>
        <h1 className={classes.title}>{title}</h1>
        <div className={classes.logoContainer}>
            <h1 className={classes.headingText}>ISO / IEC 27001:2013</h1>
            <h1 className={classes.headingText}>ISO / IEC 20000-1:2018</h1>
            <h1 className={classes.headingText}>ISO 9001:2015</h1>
            <h1 className={classes.headingText}>ISO 14001:2015</h1>
            <h1 className={classes.headingText}>CMMI Level 3</h1>
        </div>
    </div>

}

export default WeCertifiedUnder;