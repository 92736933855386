import React from 'react'
import classes from './ReactAngleCard.module.css'

function ReactangleCard({image}) {
  return <div className={classes.reactAngle}>
    <img src={image} alt="image" />
  </div>
  
}

export default ReactangleCard