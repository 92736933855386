import React from 'react';
import classes from './Card.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-regular-svg-icons';

function Card({ style, title, content}) {
    return <div className={classes.card} style={style}>
        <div className={classes.cardImage}><FontAwesomeIcon icon={faMessage} /></div>
        <div className={classes.cardContent}>
            <h3 className={classes.cardHeading}>{title}</h3>
            <p className={classes.cardPara}>{content}</p>
        </div>
    </div>

}

export default Card