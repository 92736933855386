import React from 'react'
import classes from './PrimaryButton.module.css'
import { getClasses } from '../../../utils/utils'

const PrimaryButton = ({ children, style, className, onClick, type}) => {
  return <button
    type={type}
    className={getClasses(classes.primaryBtn, className)}
    style={style}
    onClick={onClick}
  >
    {children}
  </button>

}

export default PrimaryButton