import React from 'react'
import classes from './Common.module.css'
import { contact } from '../../utils/utils';

function Refunds() {
    return (
        <div className={classes.main}>
            <div className={classes.ourStory}>
                <div className={classes.ourStoryLeft}>
                    <h1 className={classes.headingText}>Refund Policy</h1>
                    <p className={classes.contentPara} >
                    We’re so convinced you’ll absolutely love our services, that we’re willing to offer a 14 day
                    risk-free money back guarantee. If you are not satisfied with the service for any reason you
                    can get a refund within 14 days of making a purchase. Please keep in mind that even
                    though we offer a full money back guarantee, we will issue a refund only for the unused
                    portion of the service.</p>
                </div>
                <div className={classes.ourStoryLeft}>
                <h2 className={classes.subHeadingText}>Additional Services</h2>
                    <p className={classes.contentPara} >
                    Please note that any additional services, custom work or technical support are non-
                    refundable as our time cannot be recovered.</p>
                </div>
                <div className={classes.ourStoryLeft}>
                <h2 className={classes.subHeadingText}>Contact Us</h2>
                    <p className={classes.contentPara} >
                        <a href={`tel:${contact.phone}`}>
                            {contact.phone}
                        </a>
                    </p>
                    <p className={classes.contentPara} >
                        <a href={`mailto:${contact.mail}`}>
                            {contact.mail}
                        </a>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Refunds