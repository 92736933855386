import React from 'react'
import classes from './AboutUs.module.css'
import ourStoryImage from '../../assets/aboutus/Our story.jpg'
import WeCertifiedUnder from '../../components/WeCertifiedUnder/WeCertifiedUnder'
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission'
import PersonCard from '../../components/Cards/PersonCard'
import Deepak from '../../assets/meetourteam/deepak_garg.jpg'
import Sameer from '../../assets/meetourteam/sameer_manuja.jpg'
import Abhijeet from '../../assets/meetourteam/abhijeet_pic.jpg'
import Pralay from '../../assets/meetourteam/Pralay-photo.png'
import Manu from '../../assets/meetourteam/manu-photo.jpg'
import SlidingButtons from '../../components/Buttons/SlidingButtons/SlidingButtons';
import ourMission from '../../assets/aboutus/Our Mission.jpg';
import ourVision from '../../assets/aboutus/Our Vision.jpg';

function AboutUs() {
    return (
        <div className={classes.main}>
            <div className={classes.ourStory}>
                <div className={classes.ourStoryLeft}>
                    <h1 className={classes.headingText}>Our Story</h1>
                    <p className={classes.contentPara} >
                        Vir Softech is at the forefront of the revolution in business-led IT process transformation. We
                        assist our customers in realizing their vision and scaling up through workflow transformations
                        that produce superior business results at the lowest possible cost and in the shortest amount
                        of time.
                    </p>
                    <p className={classes.contentPara} >
                        Vir Softech is a tech leader in four domains that include enterprise content management &amp;
                        digital imaging, print technology-based products &amp; services, design &amp; artwork process
                        automation and eVAL Exam- educational evaluation, assessments and, learning solutions.
                        We have revolutionized technology and built solutions for firms of all sizes including fortune
                        500 companies, banks, government departments, Corporate, agencies, schools, universities
                        and coaching institutions.
                    </p>
                </div>
                <div className={classes.ourStoryRight}>
                    <img src={ourStoryImage} alt="our story" />
                </div>
            </div>
            <WeCertifiedUnder
                title={'We are Certified Under'}
                style={{ background: '#F0E3FF' }}
            />
            <OurVisionAndMission
                title={'Our Mission'}
                image={ourMission}
                content={"Our mission involves making exams more convenient for both students and organizations conducting assessments. This could include features such as flexible scheduling, remote access, and the ability to take exams from the comfort of one's own location. The aim is to simplify the assessment and evaluation by embracing emerging technologies like artificial intelligence, machine learning, and data analytics to enhance the user experience, streamline processes, and provide cutting-edge features to clients."}
            />
            <OurVisionAndMission
                title={'Our Vision'}
                image={ourVision}
                content={"Our vision as an online examination portal company encompasses a global presence, technological leadership, collaborative partnerships, continuous innovation, and a focus on academic excellence and social impact. It reflects our aspirations to revolutionize the assessment landscape and contribute to the advancement of education on a broad scale."}
                right
            />
            <div className={classes.meetOurTeam}>
                <div className={classes.headingContainer}>
                    <h1 className={classes.headingText} style={{ color: '#1C2126' }}>Meet Our Team</h1>
                    <p className={classes.contentPara}>A few passionate technologists behind world’s foremost enterprise Imaging and Print products formed Vir Softech in Nov 2015. With time the team has expanded to include brilliant product engineers, research scientists, programmers, workflow engineers, data scientists, marketing executives, and dev-ops experts.</p>
                </div>
                <div className={classes.cardsContainer}>
                    <PersonCard
                        title={'Deepak Garg'}
                        designation={'Managing Director'}
                        profilePicture={Deepak}
                    />
                    <PersonCard
                        title={'Abhijeet Jain'}
                        designation={'Chief Technology Officer'}
                        profilePicture={Abhijeet}
                    />
                    <PersonCard
                        title={'Pralaypati Ta'}
                        designation={'Principal Architect'}
                        profilePicture={Pralay}
                    />
                    <PersonCard
                        title={'Sameer Manuja'}
                        designation={'Principal Architect'}
                        profilePicture={Sameer}
                    />
                    <PersonCard
                        title={'Manu Paliwal'}
                        designation={'Vice President – Sales'}
                        profilePicture={Manu}
                    />
                </div>
            </div>
            {/* <div className={classes.benefitContainer}>
                <div>
                    
                </div>
             </div> */}
        </div>
    )
}

export default AboutUs