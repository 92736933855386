import React from 'react'
import classes from './PlanCard.module.css'
import PrimaryButton from '../Buttons/PrimryButton/PrimaryButton'
import { faCheck} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getClasses } from '../../utils/utils'


function PlanCard({PlaneHeading, planeContent}) {
    return <div className={classes.plans}>
        <h1 className={classes.planHeading}>{PlaneHeading}</h1>
        <p className={classes.bluredPara}>{planeContent}</p>
        <a href="/contactus">
            <PrimaryButton style={{ width: "12.31rem", height: "2.87rem", fontSize: "1rem", margin: '1rem' }} className={classes.hoveredButton}>Click to know the price</PrimaryButton>
        </a>
    </div>
}

export default PlanCard