import React from 'react'
import classes from './Common.module.css'

function TermsAndConditions() {
    return (
        <div className={classes.main}>
            <div className={classes.ourStory}>
                <div className={classes.ourStoryLeft}>
                    <h1 className={classes.headingText}>Terms of Use</h1>
                    <p className={classes.contentPara} >
                    We would request you to kindly read the terms of service carefully and consider these as the terms
                    of agreement between you and us. This governs the use and access to the website and the services
                    to you and your associates.</p>
                    <p className={classes.contentPara} >
                    Eval Exam is an Online Examination System to streamline your preparation and performance. The
                    terms will be liable to a customer the moment the customer creates an account on the website.
                    These terms are effective to timely amendments on the choice of the provider.</p>
                </div>
                <div className={classes.ourStoryLeft}>
                <h2 className={classes.subHeadingText}>1. Services and Product</h2>
                    <p className={classes.contentPara} >
                    We agree to offer the customer an account on the online assessment platform using a private
                    server. Considering an event of a planned change in the provider’s network or infrastructure, the
                    provider shall make practical efforts in order to notify the customer in advance. You agree to provide
                    accurate and updated identification information during the account registration. The customer is
                    solely responsible for the entire content shared on their account and also agrees to promptly notify in
                    case of an unauthorized use or breach of the account. We will not be responsible for any loss or
                    damage arising from the failure to provide accurate information or impose account security.</p>
                    <p className={classes.contentPara} >
                    The terms to provide service are between the provider and the customer. No third party recipient
                    interference is permitted. The provider should respect the privacy of the content and take utmost
                    care in order to safeguard any illegal usage. Service is dependent on factors that include the
                    network, servers and other subsidiary tools. Considering a case in which the software behaves
                    uneven and the user gets an error, our team will work to resolve the issue by earliest, understanding
                    the critical nature of the exam related events.</p>
                </div>

                <div className={classes.ourStoryLeft}>
                <h2 className={classes.subHeadingText}>2. Terms</h2>
                    <p className={classes.contentPara} >
                    The customer agrees to the service starting on the date of the subscription. The service is timely and
                    when due for renewal, we will be informing the customer in advance about the renewal date.
                    Customers will be provided a week’s time as the grace time and the customer is liable to cancel their
                    account by requesting a termination of the subscription.</p>
                </div>
                <div className={classes.ourStoryLeft}>
                <h2 className={classes.subHeadingText}>3. Usage Policy</h2>
                    <p className={classes.contentPara} >
                    A. Prohibited Content and Activities - Email spam- Not allowed for using our services to
                    transmit email spam, junk mail, return address and mailing lists. Hacking – IP scanning,
                    denial of service, or other residuals to the security breaches or attempts interfering service of
                    other networks or servers. Intellectual property- The intellectual rights are reserved along
                    with the copyright, trademark, or the patent rights. Fraud, violation of privacy rights,
                    dissemination of offensive context and any kind of illegal activities.</p>
                    <p className={classes.contentPara} >
                    B. False Information - Your personal information including name, email and contact details
                    shared should be correct. We have the authorization to block your account in case of any
                    information mismatch.</p>
                    <p className={classes.contentPara} >
                    C. Misuse of System Resources - Multiple accounts will not be accepted. If found multiple
                    accounts under the name of a single organization, the first account will be served as your
                    primary account. The prior created accounts will be blocked without any notice. The attempts
                    of questions are restricted if it extends a particular number for a particular subscription.</p>
                    <p className={classes.contentPara} >
                    D. Concurrency - The concurrency provided will be of the order of 10% of the monthly limit of
                    the number of attempts - as per the plan enrolled by the customer. ThinkExam is not
                    responsible for any crashes / damages caused and shall bear no loss - whatsoever in case
                    the concurrency is found to be more than 10%. Please ensure to discuss your exact exam
                    usage - before conducting the exams.</p>
                </div>


                <div className={classes.ourStoryLeft}>
                <h2 className={classes.subHeadingText}>4. Account Cancellation</h2>
                    <p className={classes.contentPara} >
                    You will be solely responsible for the cancellation of your account, data backup and deletion of
                    information. The entire content from the panel will be deleted once instructed for the cancellation of
                    the account.
                    </p>
                </div>

                <div className={classes.ourStoryLeft}>
                    <h2 className={classes.subHeadingText}>5. Governing Law</h2>
                    <p className={classes.contentPara} >
                    This agreement is designed and governed in reference to the laws of India any dispute shall be
                    heard at the local administrative bodies.
                    </p>
                </div>

            </div>
        </div>
    )
}

export default TermsAndConditions