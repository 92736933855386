import React from 'react';
import classes from './LandingPopUP.module.css'

function LandingPopUp({onClose}) {
    return <div class={classes.popupContainer}>
<div class={classes.promoPpopup}>
        <div class={classes.closePopup} id="closeBtn"><a href="#" onClick={onClose}>X</a></div>
            <a href="/contactus">
                <img src="https://www.virsoftech.com/img/independence_day.png" style={{width: "100%"}} />
            </a>
            <a href="/contactus" class={classes.popupBtn}>
                Contact us
            </a>
        </div>
    </div>
}

export default LandingPopUp