import React from 'react'
import classes from './CircleCard.module.css'

function CircleCard({image}) {
  return <div className={classes.circle}>
    <img src={image} alt="image" />
  </div>
  
}

export default CircleCard