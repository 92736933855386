import React, { useState } from 'react'
import classes from './Section1B.module.css'
import SlidingButtons from '../Buttons/SlidingButtons/SlidingButtons'
import Card from '../Cards/Card'

const cards = [
    {
        title: 'Question Bank',
        content: 'Checkout the myriad of question types we support like MCQs, true/false, fill in the blanks, match matrix, essay questions, etc.'
    },
    {
        title: 'Mobile application',
        content: 'A modern approach to assessment, accessibility, convenience, security, and valuable insights for administrators and exam takers alike.'
    },
    {
        title: 'Reporting',
        content: 'You can generate an overall comprehensive performance report that provides an overview of exam results.'
    },
    {
        title: 'Monetize',
        content: 'Increase your revenue by Implementing a pay-per-test model.'
    },
    {
        title: 'Proctoring',
        content: 'Our solutions include remote proctoring tools, AI-based monitoring systems that ensure fair and secure exams.'
    },
]

function Section1B() {

    const [visibleCards, setVisibleCards] = useState(4);

    const handleNext = () => {
        const totalCards = cards.length;
        const newVisibleCards = visibleCards + 1;
        if (newVisibleCards <= totalCards) {
            setVisibleCards(newVisibleCards);
        }
    };

    const handlePrevious = () => {
        const newVisibleCards = Math.max(visibleCards - 1, 4);
        setVisibleCards(newVisibleCards);
    };

    const startIndex = visibleCards - 4;
    const visibleCardSlice = cards.slice(startIndex, visibleCards);
    return <div className={classes.section1B}>
        <div className={classes.upperContainer}>
            <h1 className={classes.upperHeading}>What we offer?</h1>
            <p className={classes.upperContent}>Ours is an exceptional platform for conducting online exams with a smooth system that will enhance your experience and streamline processes.</p>
        </div>
        <div className={classes.middleContainer}>
            {
                cards.map((card, index) => {
                    return <Card key={index} title={card.title} content={card.content} />
                })
            }
        </div>
    </div>

}

export default Section1B