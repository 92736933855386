import { useEffect, useRef, React } from 'react';
import { useLocation } from 'react-router-dom';
import classes from './Education.module.css'
import QuestionBank from '../../components/QuestionBank/QuestionBank'
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import assismentFormCOching from '../../assets/education/assesment-for-coching.jpg';
import entranceExam from '../../assets/education/entrance-exam.jpg';
import remoteInvigilation from '../../assets/education/remote-invigilation.jpg';
import descriptiveTest from '../../assets/education/descriptive-test.jpg';

function Education() {
  const location = useLocation();
  const sectionRefs = useRef({});

  useEffect(() => {
    if (location.hash && sectionRefs.current[location.hash.substr(1)]) {
      const sectionElement = sectionRefs.current[location.hash.substr(1)];
      setTimeout(() => {
        sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 10);
    }
  }, [location.hash]);
  

  const handleSectionRef = (sectionId) => (ref) => {
    sectionRefs.current[sectionId] = ref;
  };

  return (
    <div className={classes.main}>
      <QuestionBank title={"Education"} />
      <OurVisionAndMission
        id= {"assessments"}
        innerRef={handleSectionRef("assessments")}
        image={assismentFormCOching}
        title={'Assessment for Educational Institutions'}
        content={<><br/><br/>With our advanced tools and features, we offer comprehensive assessment solutions specifically designed for coaching institutes where they can create, administer and analyze assessments catering to their unique curriculum and teaching methodologies.<br/><br/> Ensure well rounded evaluation of your student’s knowledge and skills with diverse question types and robust analytics engine. Test your students with a variety of questions such as multiple choice, essay, and interactive exercises and get detailed performance reports and insightful data visualizations of all kinds.<br/><br/> Our platform empowers our clients with the necessary tools to evaluate their student’s performance with accurate, reliable and personalized assessment.</>}
      />
      <OurVisionAndMission
        id= {"examManagement"}
        innerRef={handleSectionRef("examManagement")}
        image={entranceExam}
        title={'Entrance exam management'}
        content={<><br/><br/>Simplify and streamline the entrance exam process by automating the entire flow from registration to result generation. Improve the efficiency and accuracy of your entrance exam process by using technologies to automate student registration, slot booking, hall ticket generation, proctored exam conduction, result generation and generating detailed analytics reports.</>}
        right
      />
      {/* <QuestionBank title={"Remote invigilation "} content={"Our platform offers remote invigilation for secure online exams with technologies like live and automated proctoring, screen monitoring and browser lockdown."}/> */}
      
      <OurVisionAndMission
        id= {"remoteInvigilation"}
        image={remoteInvigilation}
        innerRef={handleSectionRef("remoteInvigilation")}
        title={'Remote invigilation'}
        content={<><br/><br/>Our platform offers remote invigilation for secure online exams with technologies like live and automated proctoring, screen monitoring and browser lockdown.<br/><br/><b>Live Proctoring</b>: Live proctoring involves a proctor monitoring the candidates in real-time through video and audio feeds. <br/><br/><b>Automated Proctoring</b>: Automated proctoring uses AI and machine learning algorithms to monitor candidates during the exam. <br/><br/><b>Screen Monitoring</b>: Remote proctoring software can monitor candidates' computer screens to detect any unauthorized activities.<br/><br/><b>Browser lockdown</b>: Remote proctoring software can restrict candidates' access to external websites or applications during the exam by locking down their browsers.
        </>}
      />


      {/* <OurVisionAndMission
        id= {"liveProctoring"}
        title={'Live Proctoring'}
        content={"Live proctoring involves a proctor monitoring the candidates in real-time through video and audio feeds."}
      /> */}
      {/* <OurVisionAndMission
        id= {"automatedProctoring"}
        title={'Automated Proctoring:'}
        content={"Automated proctoring uses AI and machine learning algorithms to monitor candidates during the exam."}
        right
      /> */}
      {/* <OurVisionAndMission
        id= {"screenMonitoring"}
        title={'Screen Monitoring: '}
        content={"Remote proctoring software can monitor candidates' computer screens to detect any unauthorized activities. "}
      /> */}
      {/* <OurVisionAndMission
        id= {"browserlockdown"}
        title={'Browser lockdown:'}
        content={"Remote proctoring software can restrict candidates' access to external websites or applications during the exam by locking down their browsers."}
        right
      /> */}
      <OurVisionAndMission
        id= {"descriptiveTest"}
        image ={descriptiveTest}
        innerRef={handleSectionRef("descriptiveTest")}
        title={'Descriptive tests '}
        content={<><br/><br/>Our platform caters to the needs of both - the institutes as well as students and provides robust support for descriptive tests. Be it essay writing, case study analysis, or problem-solving exercises, our platform ensures that the institutes assess student’s comprehension, creativity and communication skills effectively.<br/><br/>Incorporate descriptive tests into your evaluation to gain a holistic understanding of the student's capabilities, facilitate comprehensive learning outcomes and overall academic growth. 
        </>}
        right
      />
    </div>
  )
}

export default Education