import React from 'react'
import classes from './GetInTouch.module.css'
import mail from '../../assets/getintouch/mail.svg'
import phone from '../../assets/getintouch/phone.svg'
import { contact } from '../../utils/utils';
import whatsapp from '../../assets/social/whatsapp.svg'

function GetInTouch() {
  return (
    <div className={classes.getinTouch}>
      <p className={classes.message}>Get in touch with us !</p>
      <div className={classes.options}>
        <div className={classes.whatsapp}>
          <img src={whatsapp} alt="whatsapp" />
          <a href="https://wa.me/+919319275051" target='_blank'>WhatsApp</a>
        </div>
        <div className={classes.phone}>
          <a href={`tel:${contact.phone}`} className={classes.flex}>
            <img src={phone} alt="phone" />
            <p>{contact.phone}</p>
          </a>
        </div>
        <div className={classes.email}>
          <a href={`mailto:${contact.mail}`} className={classes.flex}>
            <img src={mail} alt="mail" />
            <p>{contact.mail}</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default GetInTouch