import React from 'react'
import classes from './Table.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons'

function Table({numberOfUser, free, basic, business, enterprise}) {
    return <table>
        <thead>
            <tr>
                <th className={classes.featurePoint}>Feature listing</th>
                <th>FREE</th>
                <th>Basic</th>
                <th>Business</th>
                <th>Enterprise</th>
            </tr>

        </thead>
        <tbody>
            <tr>
                <td className={classes.featurePoint}>{numberOfUser}</td>
                <td>{free}</td>
                <td>{basic}</td>
                <td>{business}</td>
                <td>{enterprise}</td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Test Creation</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Question Bank Mangement</td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Result Reports</td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Monitise</td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Objective/Subjective capturing</td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
            </tr>
            <tr>
                <td className={classes.featurePoint}> Logo Integration</td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck}/></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Technical Support</td>
                <td>Email & phone</td>
                <td>Email & phone</td>
                <td>Email & phone</td>
                <td>Dedicated Account Manager</td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Candidate Image capturing</td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Proctoring</td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td>Add on</td>
                <td>Add on</td>
                <td>Add on</td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Android Application</td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td>Standard</td>
                <td>Personalized</td>
                <td>Personalized</td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>iOS Application</td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td>Personalized</td>
                <td>Personalized</td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>White Label</td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>

            </tr>
            <tr>
                <td className={classes.featurePoint}>API Integration</td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
            </tr>
            <tr>
                <td className={classes.featurePoint}>Customizations</td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.no}><FontAwesomeIcon icon={faXmark} /></td>
                <td className={classes.yes}><FontAwesomeIcon icon={faCheck} /></td>
            </tr>
        </tbody>
    </table>
}

export default Table