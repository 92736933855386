import React from 'react'
import classes from './Reporting.module.css'
import QuestionBank from '../../components/QuestionBank/QuestionBank';
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import testPerformanceAnalysis from '../../assets/features/reporting tool- test performance analysis.jpg';
import progressTracking from '../../assets/features/reporting tool - progress tracking.jpg';
import candidatePerformanceReports from '../../assets/features/reporting tool - candidate performance report.jpg';
import comparativeAnalysis from '../../assets/features/reporting tool - comparative analysis.jpg';

function ReportingTool() {
    return <div className={classes.main}>
        <QuestionBank
            title={'Reporting Tool'}
            content={"Here are some key features typically found in a reporting system within an online examination portal"} />
        <OurVisionAndMission
            title={'Test Performance Analysis'}
            image={testPerformanceAnalysis}
            content={"The reporting system provides an overview of test performance, including aggregate statistics such as average scores, highest and lowest scores, and score distribution. This helps identify trends and patterns in candidate performance."}
        />
        <OurVisionAndMission
            image={progressTracking}
            title={'Progress Tracking'}
            content={"reporting system allows administrators and educators to track the progress of candidates over time. This includes tracking performance in multiple assessments or comparing performance across different time periods, facilitating longitudinal analysis."}
            right
        />
        <OurVisionAndMission
            image={candidatePerformanceReports}
            title={'Candidate Performance Reports'}
            content={"The reporting system generates individual performance reports for each candidate. These reports include detailed information about the candidate's score, performance in different test sections, and a breakdown of scores by question type or topic. This helps identify strengths and weaknesses of candidates."}
        />
        <OurVisionAndMission
            image={comparativeAnalysis}
            title={'Comparative Analysis'}
            content={"The reporting system enables administrators to compare the performance of different groups of candidates, such as cohorts, classes, or schools. It helps identify variations in performance and understand the effectiveness of educational programs."}
            right
        />
    </div>

}

export default ReportingTool