import React from 'react'
import classes from './ReviewCard.module.css'
import StarRatings from 'react-star-ratings'

function ReviewCard({userImage, rating, content, userName, userDetail }) {
    return <div className={classes.main}>
        <div className={classes.allContentContainer}>
            <div className={classes.leftContainer}>
                <div className={classes.imageContainer}>
                    <img src={userImage} alt="personImage" />
                </div>
                <span className={classes.rightCornerCircle}></span>
                <span className={classes.leftTopUpperCornerCircle}></span>
                <span className={classes.leftTopBottomCornerCircle}></span>
                
            </div>
            <div className={classes.rightContainer}>
                <p className={classes.doubleQuotes}>‘‘</p>
                <StarRatings
                    rating={rating || 4}
                    starRatedColor="#ff7f5c"
                    numberOfStars={5}
                    starDimension="2rem"
                    starSpacing="0.125rem"
                />
                <p className={classes.content}>{content}</p>
                <div className={classes.userDetails}>
                    <h2 className={classes.userName}>{userName}</h2>
                    <span>{userDetail}</span>
                </div>
            </div>
        </div>
    </div>
}

export default ReviewCard