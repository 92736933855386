import { useEffect, useRef, React } from 'react';
import { useLocation } from 'react-router-dom';
import classes from './Corporate.module.css'
import QuestionBank from '../../components/QuestionBank/QuestionBank'
import OurVisionAndMission from '../../components/OurVisionAndMission/OurVisonAndMission';
import campusHiring from '../../assets/corporate/campus-hiring.jpg';
import remoteProctoring from '../../assets/corporate/remote-proctoring.jpg';
import skillAssesment from '../../assets/corporate/skill assessment.jpg'
import recruitmentAssessment from '../../assets/corporate/recruitment-assessment.jpg'

function Corporate() {
    const location = useLocation();
  const sectionRefs = useRef({});

  useEffect(() => {
    if (location.hash && sectionRefs.current[location.hash.substr(1)]) {
        const sectionElement = sectionRefs.current[location.hash.substr(1)];
        setTimeout(() => {
            sectionElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 20);
        }
    }, [location.hash]);
    

    const handleSectionRef = (sectionId) => (ref) => {
        sectionRefs.current[sectionId] = ref;
    };

    return (
        <div className={classes.main}>
            <QuestionBank title={"Corporate"} />
            <OurVisionAndMission
                id="skillAssessments"
                image={skillAssesment}
                innerRef={handleSectionRef("skillAssessments")}
                title={'Skill assessment'}
                content={<><br/><br/>With our comprehensive tools and features, conduct specialized skill assessment to evaluate and measure the skills and competencies of your employees.<br/><br/>Customize your assessments and get detailed analytics and reports to identify strengths and address skill gaps. Our platform helps you to measure employee proficiency, identify skill gaps, and tailor training and development programs to enhance organizational performance.</>}
            />
            <OurVisionAndMission
                id="recruitmentAssessment"
                image= {recruitmentAssessment}
                innerRef={handleSectionRef("recruitmentAssessment")}
                title={'Recruitment assessment'}
                content={<><br/><br/>Hiring and recruitment can be a costly and time consuming process. Simplify and automate your candidate hiring process. Save time and money by using the technologies we offer.
                <br/><br/>With technologies like AI based remote proctoring, anti-cheat mechanisms like screen freeze, audit logs and much more, we ensure that you hire the best candidates with efficient and fair assessment for all. </>}
                right
            />
            {/* <QuestionBank title={"Remote proctoring"} content={'Our platform offers remote invigilation for secure online exams with technologies like live and automated proctoring, screen monitoring and browser lockdown.'} /> */}
            <OurVisionAndMission
                id="remoteProctoring"
                image= {remoteProctoring}
                innerRef={handleSectionRef("remoteProctoring")}
                title={'Remote proctoring'}
                content={<><br/><br/>Our platform offers remote invigilation for secure online exams with technologies like live and automated proctoring, screen monitoring and browser lockdown.<br/><br/><b>Live Proctoring</b>: Live proctoring involves a proctor monitoring the candidates in real-time through video and audio feeds.<br/><br/><b>Automated Proctoring</b>: Automated proctoring uses AI and machine learning algorithms to monitor candidates during the exam.<br/><br/><b>Screen Monitoring</b>: Remote proctoring software can monitor candidates' computer screens to detect any unauthorized activities.<br/><br/><b>Browser Lockdown</b>: Remote proctoring software can restrict candidates' access to external websites or applications during the exam by locking down their browsers.</>}
            />
            <OurVisionAndMission
                id="campusRecruitment"
                image= {campusHiring}
                innerRef={handleSectionRef("campusRecruitment")}
                title={'Campus recruitment solution'}
                content={<><br/><br/>Save time, cut back on administrative cost and burden and find the right candidates for your organization through our platform.<br/><br/>We offer a simple, seamless, efficient campus recruitment solution and provide tools and features that help you streamline the entire process for you to connect with the top talent.<br/><br/>With our user friendly interface for both recruiters and candidates, the campus recruitment becomes efficient and hassle free.</>}
                right
            />

            {/* <OurVisionAndMission
                title={'Automated Proctoring:'}
                content={" Automated proctoring uses AI and machine learning algorithms to monitor candidates during the exam."}
            /> */}

            
            {/* <OurVisionAndMission
                title={'Browser Lockdown:'}
                content={"Remote proctoring software can restrict candidates' access to external websites or applications during the exam by locking down their browsers."}

            /> */}

        </div>
    )
}

export default Corporate